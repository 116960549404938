/* eslint-disable no-console */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import Layout from 'components/layout';
import FaqSection from '../components/faq-section/faq-section';
import {
  Fonts,
  FlipCardStyle,
  HeroWhatCTStyle,
  SliderStyle,
  ShadowSec,
  TabletMarginStyle,
  PageBody,
  VideoContainer,
} from '../global.css';
import Img from 'gatsby-image';
import { Row, Col } from 'reactstrap';
// import Slider from "react-slick";
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';
import { replaceCompanyName, getCompanyData } from '../constants/config';
import Video from '../components/player/wistia';
import Loading from '../components/loading/loading';

const WhatIsAClinicalTrial = ({ data }) => {
  const [scriptsLoading, setScriptsLoading] = useState(true);
  const [pageLoading, setPageLoading] = useState(true);
  const [aliceIndex, setAliceIndex] = useState(0);
  const [exp, setExp] = useState(
    data.whatIsAClinicalTrialEnJson.phases.map(() => {
      return true;
    })
  );
  const [flip, setFlip] = useState(
    data.whatIsAClinicalTrialEnJson.flipCards.map(() => {
      return true;
    })
  );
  const responsive = {
    320: { items: 1 },
    699: { items: 2 },
    1023: { items: 3 },
    1300: { items: 4 },
  };

  useEffect(() => {
    if (typeof window !== 'undefined') {
      setScriptsLoading(false);
    }
    orientationChange();
    return () => {
      if ('onorientationchange' in window) {
        window.removeEventListener(
          'orientationchange',
          () => setAliceIndex(0),
          false
        );
      }
    };
  }, []);

  const orientationChange = () => {
    if ('onorientationchange' in window) {
      window.addEventListener(
        'orientationchange',
        () => setAliceIndex(0),
        false
      );
    }
  };

  const onSlideChange = (e) => {
    console.debug('Item`s position during a change: ', e.item);
    console.debug('Slide`s position during a change: ', e.slide);
  };

  const onSlideChanged = (e) => {
    console.debug('Item`s position after changes: ', e.item);
    console.debug('Slide`s position after changes: ', e.slide);
  };

  const change = (e) => {
    let number = aliceIndex;
    if (e.srcElement.className.indexOf('inactive') < 0) {
      if (e.srcElement.className.indexOf('next') > -1) {
        number = number + 1;
        setAliceIndex(number);
      } else {
        number = number - 1;
        setAliceIndex(number);
      }
    }
  };

  const loading = () => {
    setPageLoading(false);
  };

  const toggle = (itemIndex) => {
    if (
      typeof window.orientation === 'undefined' ||
      navigator.userAgent.indexOf('Mobile') < 0
    ) {
      let localFlip = flip;
      setFlip(
        localFlip.map((value, index) => (index != itemIndex ? true : !value))
      );
    }
  };

  const toggleMobile = (itemIndex) => {
    if (
      typeof window.orientation !== 'undefined' ||
      navigator.userAgent.indexOf('Mobile') > -1
    ) {
      let localFlip = flip;
      setFlip(
        localFlip.map((value, index) => (index != itemIndex ? true : !value))
      );
    }
  };

  const handleClick = (event, itemIndex) => {
    if (
      event &&
      event.target &&
      event.target.href &&
      (event.target.href.indexOf('phases') > -1 ||
        event.target.href.indexOf('phases') > -1)
    ) {
      event.preventDefault();
      event.stopPropagation();
      let collapse = exp;
      setExp(
        collapse.map((value, index) => (index !== itemIndex ? true : !value))
      );
    }
  };

  return scriptsLoading ? (
    ''
  ) : (
    <PageBody>
      <div className={!pageLoading ? 'hidden' : ''}>
        <Loading />
      </div>
      <div className={pageLoading ? 'hidden' : ''}>
        <Layout
          meta={replaceCompanyName(data.whatIsAClinicalTrialEnJson, 'seo').seo}
        >
          <div className={'content-container'}>
            <Fonts>
              <TabletMarginStyle>
                <HeroWhatCTStyle>
                  <Col lg={4} md={12} sm={12} className={'heroText'}>
                    <h1>{data.whatIsAClinicalTrialEnJson.heroTitle}</h1>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: replaceCompanyName(
                          data.whatIsAClinicalTrialEnJson.content
                            .childMarkdownRemark.html
                        ),
                      }}
                    />
                  </Col>
                  <Col lg={8} md={12} sm={12} className={'hero-video'}>
                    <Video
                      code={getCompanyData(
                        data.whatIsAClinicalTrialEnJson,
                        'video'
                      )}
                      title={getCompanyData(
                        data.whatIsAClinicalTrialEnJson,
                        'videoTitle'
                      )}
                    />
                    <p className={'permission'}>
                      {data.whatIsAClinicalTrialEnJson.videoPermission}
                    </p>
                  </Col>
                </HeroWhatCTStyle>
                <SliderStyle id={'phases'}>
                  <ShadowSec className={'phases'} />
                  <Col xs={12} className={'align-elements'}>
                    <h2>{data.whatIsAClinicalTrialEnJson.phasesTitle}</h2>
                  </Col>
                  <Col lg={9} xs={12} className={'align-elements'}>
                    <div
                      className={'phases-subtitle'}
                      dangerouslySetInnerHTML={{
                        __html: replaceCompanyName(
                          data.whatIsAClinicalTrialEnJson.phasesContent
                            .childMarkdownRemark.html
                        ),
                      }}
                    />
                  </Col>
                  <AliceCarousel
                    duration={0}
                    items={data.whatIsAClinicalTrialEnJson.phases.map(
                      (phases, i) => (
                        <Col
                          className={'col-item align-elements'}
                          onClick={(event) => handleClick(event, i)}
                          key={i}
                        >
                          <Img
                            fluid={phases.img.childImageSharp.fluid}
                            alt={phases.altTitle}
                            durationFadeIn={0}
                            loading="eager"
                          />
                          <h3 className={'phase-title'}>
                            {replaceCompanyName(phases.title)}
                          </h3>
                          {exp[i] ? (
                            <div
                              className={'phases-text'}
                              dangerouslySetInnerHTML={{
                                __html: replaceCompanyName(
                                  phases.content.childMarkdownRemark.html
                                ),
                              }}
                            />
                          ) : (
                            <div
                              className={'phases-text'}
                              dangerouslySetInnerHTML={{
                                __html: replaceCompanyName(
                                  phases.contentExp.childMarkdownRemark.html
                                ),
                              }}
                            />
                          )}
                        </Col>
                      )
                    )}
                    responsive={responsive}
                    autoPlayInterval={2000}
                    autoPlayDirection="rtl"
                    autoPlay={false}
                    startIndex={aliceIndex}
                    fadeOutAnimation={true}
                    mouseDragEnabled={false}
                    swipeDisabled={true}
                    playButtonEnabled={false}
                    dotsDisabled={true}
                    infinite={false}
                    disableAutoPlayOnAction={true}
                    onSlideChange={(e) => {
                      onSlideChange(e);
                      change(event);
                    }}
                    onSlideChanged={(e) => onSlideChanged(e)}
                  />
                </SliderStyle>
                <Row>
                  <Col xs={12} lg={{ size: 8, offset: 2 }}>
                    <VideoContainer>
                      <Video
                        code={getCompanyData(
                          data.whatIsAClinicalTrialEnJson,
                          'phasesVideo'
                        )}
                        title={getCompanyData(
                          data.whatIsAClinicalTrialEnJson,
                          'phasesVideoTitle'
                        )}
                        pageLoading={loading}
                      />
                      <p className={'footnote'}>
                        {data.whatIsAClinicalTrialEnJson.phasesVideoTitle}
                      </p>
                    </VideoContainer>
                  </Col>
                </Row>
                <FlipCardStyle>
                  <ShadowSec className={'stepsCT'} />
                  <Col md={12} sm={12} tag={'h2'} className={'stepsTitle'}>
                    {replaceCompanyName(
                      data.whatIsAClinicalTrialEnJson.stepsTitle
                    )}
                  </Col>
                  {data.whatIsAClinicalTrialEnJson.flipCards.map(
                    (cards, index) => (
                      <Col
                        sm={6}
                        md={6}
                        lg={4}
                        key={index}
                        onMouseEnter={() => toggle(index)}
                        onMouseLeave={() => toggle(index)}
                        onClick={() => toggleMobile(index)}
                      >
                        {/* <div className="flip-card">
                        <div className="flip-card-inner">
                          <div className="flip-card-front"> */}
                        {flip[index] ? (
                          <Img
                            fluid={cards.img.childImageSharp.fluid}
                            alt={cards.altTitle}
                            durationFadeIn={0}
                            loading="eager"
                          />
                        ) : (
                          <div
                            className={'back-flip'}
                            dangerouslySetInnerHTML={{
                              __html: replaceCompanyName(
                                cards.content.childMarkdownRemark.html
                              ),
                            }}
                          />
                        )}
                        <h2>{cards.title}</h2>
                      </Col>
                    )
                  )}
                </FlipCardStyle>
                <div className={'faq-ct-page'}>
                  <h2 className={'faqTitle'}>{data.faqEnJson.title}</h2>
                  <FaqSection
                    id="faqs"
                    faqItems={data.faqEnJson.faqsItem
                      .filter((item) => item.whatAreCTPage > 0)
                      .sort((i1, i2) => i1.whatAreCTPage - i2.whatAreCTPage)}
                  />
                </div>
              </TabletMarginStyle>
            </Fonts>
          </div>
        </Layout>
      </div>
    </PageBody>
  );
};

WhatIsAClinicalTrial.propTypes = {
  data: PropTypes.object.isRequired,
};

export default WhatIsAClinicalTrial;

export const query = graphql`
  query WhatIsAClinicalTrial {
    whatIsAClinicalTrialEnJson {
      seo {
        pageTitle
        pageDescription
      }
      title
      heroTitle
      stepsTitle
      phasesTitle
      videoPermission
      video
      video_merck
      videoTitle
      videoTitle_merck
      phasesContent {
        childMarkdownRemark {
          html
        }
      }
      heroImg {
        childImageSharp {
          fluid(maxHeight: 500, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      content {
        childMarkdownRemark {
          html
        }
      }
      phasesTitle
      phasesContent {
        childMarkdownRemark {
          html
        }
      }
      flipCards {
        title
        altTitle
        content {
          childMarkdownRemark {
            html
          }
        }
        img {
          childImageSharp {
            fluid(maxHeight: 500, quality: 90) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
      phases {
        title
        altTitle
        content {
          childMarkdownRemark {
            html
          }
        }
        contentExp {
          childMarkdownRemark {
            html
          }
        }
        img {
          childImageSharp {
            fluid(maxHeight: 500, quality: 90) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
      phasesVideo
      phasesVideoTitle
    }
    faqEnJson {
      title
      faqsItem {
        title
        content {
          childMarkdownRemark {
            html
          }
        }
        whatAreCTPage
      }
    }
  }
`;
